import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventJumbotronParallax } from "../../../../src/components/EventJumbotronParallax";
import { SectionTypes, Section } from "../../../../src/components/Section";
import { EventFullDescription } from "../../../../src/components/EventFullDescription";
import { HackathonDefinitionSection } from "../../../../src/components/HackathonDefinitionSection";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventSponsorsSection } from "../../../../src/components/EventSponsorsSection";
import { EventPeopleSection } from "../../../../src/components/EventPeopleSection";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { ButtonsContainer } from "../../../../src/components/ButtonsContainer";
import { FaCalendarAlt, FaDiscord, FaMapMarkerAlt } from "react-icons/fa";
import { HackawayLogoText } from "../../../../src/components/HackawayLogoText";
import { Image as Whatever } from "../../../../src/components/Img";
import { EventLocationSection } from "../../../../src/components/EventLocationSection";
import { LeftHandImage } from "../../../../src/components/LeftHandImage";
import { EventScheduleSection } from "../../../../src/components/EventScheduleSection";
import { TitoWidget } from "../../../../src/components/TitoWidget";
import { EventVideoWidget } from "../../../../src/components/EventVideoWidget";
import hackaway5logo from "../../../../src/assets/images/logo/rh-white-montserrat-v5.svg";
import { video, hackaway5logoClass } from "../../../../src/posts/events/hackawayv5/v5.module.scss";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventJumbotronParallax,
  SectionTypes,
  Section,
  EventFullDescription,
  HackathonDefinitionSection,
  EventFrequentlyAskedQuestionsSection,
  EventSponsorsSection,
  EventPeopleSection,
  SiteButton,
  ButtonsContainer,
  FaCalendarAlt,
  FaDiscord,
  FaMapMarkerAlt,
  HackawayLogoText,
  Whatever,
  EventLocationSection,
  LeftHandImage,
  EventScheduleSection,
  TitoWidget,
  EventVideoWidget,
  hackaway5logo,
  video,
  hackaway5logoClass,
  React
};