import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { Section, SectionTypes } from "../../../../src/components/Section";
import { EventFullDescription } from "../../../../src/components/EventFullDescription";
import { EventCountdownSection } from "../../../../src/components/EventCountdownSection";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventLocationSection } from "../../../../src/components/EventLocationSection";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { ButtonsContainer } from "../../../../src/components/ButtonsContainer";
import { FaCalendarAlt, FaDiscord, FaMapMarkerAlt } from "react-icons/fa";
import { logo } from "../../../../src/posts/events/freshers2018/freshers2018.module.scss";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventJumbotron,
  Section,
  SectionTypes,
  EventFullDescription,
  EventCountdownSection,
  EventFrequentlyAskedQuestionsSection,
  EventLocationSection,
  SiteButton,
  ButtonsContainer,
  FaCalendarAlt,
  FaDiscord,
  FaMapMarkerAlt,
  logo,
  React
};