import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { Section, SectionTypes } from "../../../../src/components/Section";
import { EventFullDescription } from "../../../../src/components/EventFullDescription";
import { EventCountdownSection } from "../../../../src/components/EventCountdownSection";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventLocationSection } from "../../../../src/components/EventLocationSection";
import { EventPeopleSection } from "../../../../src/components/EventPeopleSection";
import { EventDate } from "../../../../src/components/EventDate";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { ButtonsContainer } from "../../../../src/components/ButtonsContainer";
import { FaDiscord } from "react-icons/fa";
import { GrTicket } from "react-icons/gr";
import freshersHackLogo from "../../../../src/posts/events/freshers2022/DiscordCanary_9EdQ4hkP3l.png";
import { logo, gamejamTopOverride, gamejamMapOverride } from "../../../../src/posts/events/freshers2022/freshers2022.module.scss";
import { FreshersJam } from "../../../../src/components/FreshersJam";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventJumbotron,
  Section,
  SectionTypes,
  EventFullDescription,
  EventCountdownSection,
  EventFrequentlyAskedQuestionsSection,
  EventLocationSection,
  EventPeopleSection,
  EventDate,
  SiteButton,
  ButtonsContainer,
  FaDiscord,
  GrTicket,
  freshersHackLogo,
  logo,
  gamejamTopOverride,
  gamejamMapOverride,
  FreshersJam,
  React
};