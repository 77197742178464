import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventLocationSection } from "../../../../src/components/EventLocationSection";
import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { Section, SectionTypes } from "../../../../src/components/Section";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventScheduleSection } from "../../../../src/components/EventScheduleSection";
import { HackawayLogo } from "../../../../src/components/HackawayLogo";
import { jumbotronBackground, hackaway3logoClass } from "../../../../src/posts/events/hackawayv3/v3.module.scss";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventLocationSection,
  EventJumbotron,
  SiteButton,
  Section,
  SectionTypes,
  EventFrequentlyAskedQuestionsSection,
  EventScheduleSection,
  HackawayLogo,
  jumbotronBackground,
  hackaway3logoClass,
  React
};