import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { Section, SectionTypes } from "../../../../src/components/Section";
import { Image as Whatever } from "../../../../src/components/Img";
import localhostLogo from "../../../../src/assets/images/logo/localhost-logo.svg";
import { localhostBackground, localhostLogoClass } from "../../../../src/posts/events/localhost2018/2018.module.scss";
import * as React from 'react';
export default {
  EventJumbotron,
  SiteButton,
  Section,
  SectionTypes,
  Whatever,
  localhostLogo,
  localhostBackground,
  localhostLogoClass,
  React
};