import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { SectionTypes } from "../../../../src/components/Section";
import { EventFullDescription } from "../../../../src/components/EventFullDescription";
import { EventCountdownSection } from "../../../../src/components/EventCountdownSection";
import { HackathonDefinitionSection } from "../../../../src/components/HackathonDefinitionSection";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventSponsorsSection } from "../../../../src/components/EventSponsorsSection";
import { EventPeopleSection } from "../../../../src/components/EventPeopleSection";
import { EventScheduleSection } from "../../../../src/components/EventScheduleSection";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { ButtonsContainer } from "../../../../src/components/ButtonsContainer";
import { FaCalendarAlt, FaDiscord, FaMapMarkerAlt } from "react-icons/fa";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventJumbotron,
  SectionTypes,
  EventFullDescription,
  EventCountdownSection,
  HackathonDefinitionSection,
  EventFrequentlyAskedQuestionsSection,
  EventSponsorsSection,
  EventPeopleSection,
  EventScheduleSection,
  SiteButton,
  ButtonsContainer,
  FaCalendarAlt,
  FaDiscord,
  FaMapMarkerAlt,
  React
};