import { MajorLeagueHackingBadge } from "../../../../src/components/MajorLeagueHackingBadge";
import { EventLocationSection } from "../../../../src/components/EventLocationSection";
import { EventJumbotron } from "../../../../src/components/EventJumbotron";
import { SiteButton } from "../../../../src/components/SiteClickable";
import { Section, SectionTypes } from "../../../../src/components/Section";
import { EventFrequentlyAskedQuestionsSection } from "../../../../src/components/EventFrequentlyAskedQuestionsSection";
import { EventScheduleSection } from "../../../../src/components/EventScheduleSection";
import { EventSponsorsSection } from "../../../../src/components/EventSponsorsSection";
import { Image as Whatever } from "../../../../src/components/Img";
import hackawayLogo from "../../../../src/assets/images/logo/rh-small.svg";
import { jumbotronBackground, hackawayLogoClass } from "../../../../src/posts/events/hackaway2019/2019.module.scss";
import * as React from 'react';
export default {
  MajorLeagueHackingBadge,
  EventLocationSection,
  EventJumbotron,
  SiteButton,
  Section,
  SectionTypes,
  EventFrequentlyAskedQuestionsSection,
  EventScheduleSection,
  EventSponsorsSection,
  Whatever,
  hackawayLogo,
  jumbotronBackground,
  hackawayLogoClass,
  React
};